import React from 'react';
import { Routes } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import Loader from '../components/Loader';
import Alert from '../components/Alert';
import { PublicContent, PublicWrapper } from './styles';
import { routesBuilder, supportRoutes } from './routes';

const Public = () => {
	const { alert } = useSelector(({ ui }) => ui, shallowEqual);

	return (
		<PublicWrapper>
			<PublicContent>
				<Alert alert={alert} />
				<React.Suspense fallback={<Loader />}>
					<Routes>{routesBuilder(supportRoutes)}</Routes>
				</React.Suspense>
			</PublicContent>
		</PublicWrapper>
	);
};

export default Public;
