import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Routes } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import Alert from '../components/Alert';
// import Loader from '../components/Loader';
import { AdminLayout, SupportLayout } from '../layouts';
import { supportRoutes, adminRoutes, routesBuilder } from './routes';
import { MainContent } from './styles';

const RoutesComp = ({ routes }) => {
	const alert = useSelector(({ ui }) => ui.alert, shallowEqual);

	return (
		<MainContent>
			<Container maxWidth='xl' sx={{ mt: '30px', pb: '40px' }}>
				<Grid
					container
					direction='row'
					justifyContent='center'
					alignItems='stretch'
					spacing={3}
				>
					<Grid item xs={12}>
						{/* {loading && <Loader />} */}
						<Alert alert={alert} />
						<React.Suspense fallback={null}>
							<Routes>{routesBuilder(routes)}</Routes>
						</React.Suspense>
					</Grid>
				</Grid>
			</Container>
		</MainContent>
	);
};

const Private = () => {
	const { isAdmin } = useSelector(({ auth }) => auth, shallowEqual);

	return isAdmin ? (
		<AdminLayout>
			<RoutesComp routes={adminRoutes} />
		</AdminLayout>
	) : (
		<SupportLayout>
			<RoutesComp routes={supportRoutes} />
		</SupportLayout>
	);
};

export default Private;
