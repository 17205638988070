import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from '@redux-saga/core';
import rootSaga from './saga/rootSaga';
import uiReducer from './reducers/ui';
import authReducer from './reducers/auth';
import requestsReducer from './reducers/request';

const loggerMiddleware = createLogger();
const sagaMiddleware = createSagaMiddleware();

const storeFunc = function configureAppStore(preloadedState) {
	const store = configureStore({
		reducer: {
			ui: uiReducer,
			auth: authReducer,
			requests: requestsReducer,
		},
		middleware: (getDefaultMiddleware) =>
			process.env.NODE_ENV !== 'production'
				? getDefaultMiddleware({ serializableCheck: false })
						.concat(sagaMiddleware)
						.concat(loggerMiddleware)
				: getDefaultMiddleware({ serializableCheck: false }).concat(
						sagaMiddleware
				  ),
		devTools: process.env.NODE_ENV !== 'production',
		preloadedState,
	});

	sagaMiddleware.run(rootSaga);

	return store;
};

const store = storeFunc();

export default store;
