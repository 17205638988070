import { createReducer } from '@reduxjs/toolkit';
import moment from 'moment';
import { requestsActions } from '../actions/requests';
import { statusType } from '../../utils';

const requestsState = {
	audit: [],
	date: moment(new Date()).format('DD MMM yyyy'),
	reqDate: { startDate: null, endDate: null },
	lastDate: { startDate: null, endDate: null },
	casinos: [],
	reasons: [],
	selectedRequests: [],
	casinoFilter: [],
	statusFilter: [],
	usersRequests: [],
	cancelledRequests: [],
	archive: [],
	showCancelled: false,
	totalCount: 0,
	perPage: 15,
};

const requestsReducer = createReducer(requestsState, (builder) => {
	builder
		.addCase(
			requestsActions.getActiveRequestsSuccessAction,
			(state, { payload }) => {
				state.usersRequests = payload?.filter(
					(el) => el.status !== statusType.CANCELLED
				);
				state.cancelledRequests = payload?.filter(
					(el) => el.status === statusType.CANCELLED
				);
			}
		)
		.addCase(
			requestsActions.getConfigurationSuccessAction,
			(state, { payload }) => {
				state.casinos = payload[0]?.casino || [];
				state.reasons = payload[0]?.reason || [];
			}
		)
		.addCase(
			requestsActions.getAuditSuccessAction,
			(state, { payload }) => {
				state.audit = payload || [];
			}
		)
		.addCase(
			requestsActions.getArchiveSuccessAction,
			(state, { payload }) => {
				state.archive = payload || [];
			}
		)
		.addCase(
			requestsActions.getTotalCountSuccessAction,
			(state, { payload }) => {
				state.totalCount = payload || 0;
			}
		)
		.addCase(requestsActions.setDateAction, (state, { payload }) => {
			state.date = payload;
		})
		.addCase(requestsActions.setReqDateAction, (state, { payload }) => {
			state.reqDate = payload;
		})
		.addCase(requestsActions.setLastDateAction, (state, { payload }) => {
			state.lastDate = payload;
		})
		.addCase(
			requestsActions.setSelectedRequestAction,
			(state, { payload }) => {
				state.selectedRequests = payload;
			}
		)
		.addCase(
			requestsActions.setCasinoFilterAction,
			(state, { payload }) => {
				state.casinoFilter = payload || [];
			}
		)
		.addCase(
			requestsActions.setStatusFilterAction,
			(state, { payload }) => {
				state.statusFilter = payload || [];
			}
		)
		.addCase(
			requestsActions.clearSelectedRequestAction,
			(state, { payload }) => {
				state.selectedRequests = [];
			}
		)
		.addCase(
			requestsActions.clearTotalAction,
			(state, { payload }) => {
				state.totalCount = 0;
			}
		)
		.addCase(
			requestsActions.clearFiltersAction,
			(state, { payload }) => {
				state.casinoFilter = [];
				state.statusFilter = [];
				state.reqDate = { startDate: null, endDate: null };
				state.lastDate = { startDate: null, endDate: null };
			}
		)
		.addCase(
			requestsActions.toggleCancelledRowsAction,
			(state, { payload }) => {
				if (state.showCancelled) {
					state.usersRequests = state.usersRequests.filter(
						(el) => el.status !== statusType.CANCELLED
					);
					state.showCancelled = false;
				} else {
					state.usersRequests = state.usersRequests
						.concat(state.cancelledRequests)
						.flat();
					state.showCancelled = true;
				}
			}
		)
		.addDefaultCase((state) => state);
});

export default requestsReducer;
