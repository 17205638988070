import moment from 'moment';
import CircularJSON from 'circular-json';
import { fork, call, takeLatest, put, select } from 'redux-saga/effects';
import { requestsActions } from '../actions/requests';
import { uiActions } from '../actions/ui';
import {
	// getTotalCount,
	getActiveRequests,
	getArchive,
	getConfiguration,
	getAudit,
} from '../../services/db';
import {
	validateUser,
	submitRequestForm,
	deleteRequestFunction,
	cancelRequestsFunction,
	confirmRequestsFunction,
	localFixFunction,
} from '../../services/api';
import { initialValues as requestFormInit } from '../../screens/RequestForm/helpers';
import { statusType } from '../../utils';

function* _getActiveRequests({ payload }) {
	const { casinos } = yield select(({ requests }) => requests);

	try {
		// const count = yield call(getTotalCount, 'ActiveRequests');
		// yield put(requestsActions.getTotalCountSuccessAction(count));
		const requests = yield call(getActiveRequests, payload);
		yield put(requestsActions.getActiveRequestsSuccessAction(requests));
		const configuration = yield !casinos?.length && call(getConfiguration);
		yield configuration && put(requestsActions.getConfigurationSuccessAction(configuration));
	} catch (error) {
		console.log(CircularJSON.stringify(error));
	}
}

function* _submitRequestForm({
	payload: { values, isAdmin, navigate, resetForm },
}) {
	yield put(uiActions.startLoaderAction());

	const user = yield call(validateUser, values);

	if (user?.data?.['getplayerinforesponse2']?.['errorcode'][0] === '0') {
		const email =
			user?.data?.['getplayerinforesponse2']?.['playerdatamap']?.[0]?.['email']?.[0];
		const phone =
			user?.data?.['getplayerinforesponse2']?.['playerdatamap']?.[0]?.['cellphone']?.[0];
		const playerCode =
			user?.data?.['getplayerinforesponse2']?.['playerdatamap']?.[0]?.['playercode']?.[0];

		const fullData = {
			...values,
			email: email || '',
			phone: phone || '',
			playerCode: playerCode || '',
		};

		if (fullData?.email && fullData?.phone && fullData?.playerCode) {
			yield call(_submitForm, fullData, isAdmin, navigate, resetForm);
		} else {
			yield call(_manageFailure);
		}
	} else {
		yield call(_manageFailure);
	}
}

function* _submitForm(fullData, isAdmin, navigate, resetForm) {
	try {
		const response = yield call(submitRequestForm, fullData);

		console.log('_submitForm response ', CircularJSON.stringify(response));

		if (response?.data === statusType.SUCCESS) {
			yield put(
				uiActions.setAlertAction({
					show: true,
					severity: 'success',
					body: 'Form was sucessfully sent',
				})
			);
			resetForm({ values: requestFormInit });
			const resetDate = moment(Date.now()).format('DD MMM yyyy');
			yield put(requestsActions.setDateAction(resetDate));
			isAdmin && navigate('/user_management');
		} else {
			yield put(
				uiActions.setAlertAction({
					show: true,
					severity: 'error',
					body: CircularJSON.stringify(response?.data),
				})
			);
		}
	} catch (error) {
		yield put(
			uiActions.setAlertAction({
				show: true,
				severity: 'error',
				body: CircularJSON.stringify(error),
			})
		);
	} finally {
		yield put(uiActions.endLoaderAction());
	}
}

function* _manageFailure() {
	yield put(uiActions.endLoaderAction());
	yield put(
		uiActions.setAlertAction({
			show: true,
			severity: 'error',
			body: `User doesn't exist`,
		})
	);
}

function* _cancelRequests({ payload }) {
	try {
		yield put(uiActions.startLoaderAction());
		const response = yield call(cancelRequestsFunction, payload);

		console.log('_cancelRequests response ', CircularJSON.stringify(response));

		if (response?.data === statusType.SUCCESS) {
			yield put(
				uiActions.setAlertAction({
					show: true,
					severity: 'success',
					body: 'Status was sucessfully updated',
				})
			);
			yield put(requestsActions.clearSelectedRequestAction());
			yield put(requestsActions.getActiveRequestsAction({}));
		} else {
			yield put(
				uiActions.setAlertAction({
					show: true,
					severity: 'error',
					body: CircularJSON.stringify(response?.data),
				})
			);
		}
	} catch (error) {
		yield put(
			uiActions.setAlertAction({
				show: true,
				severity: 'error',
				body: CircularJSON.stringify(error),
			})
		);
	} finally {
		yield put(uiActions.endLoaderAction());
	}
}

function* _confirmRequests({ payload }) {
	try {
		yield put(uiActions.startLoaderAction());
		const response = yield call(confirmRequestsFunction, payload);

		console.log('_confirmRequests response ', CircularJSON.stringify(response));

		if (response?.data === statusType.SUCCESS) {
			yield put(
				uiActions.setAlertAction({
					show: true,
					severity: 'success',
					body: 'Deletion confirmed',
				})
			);
			yield put(requestsActions.clearSelectedRequestAction());
			yield put(requestsActions.getActiveRequestsAction({}));
		} else {
			yield put(
				uiActions.setAlertAction({
					show: true,
					severity: 'error',
					body: CircularJSON.stringify(response?.data),
				})
			);
		}
	} catch (error) {
		yield put(
			uiActions.setAlertAction({
				show: true,
				severity: 'error',
				body: CircularJSON.stringify(error),
			})
		);
	} finally {
		yield put(uiActions.endLoaderAction());
	}
}

function* _deleteRequests({ payload }) {
	try {
		yield put(uiActions.startLoaderAction());
		const response = yield call(deleteRequestFunction, payload);

		console.log('_deleteRequests response ', CircularJSON.stringify(response));

		if (response?.data === statusType.SUCCESS) {
			yield put(
				uiActions.setAlertAction({
					show: true,
					severity: 'success',
					body: 'RTBF in progress',
				})
			);
			yield put(requestsActions.clearSelectedRequestAction());
			yield put(requestsActions.getActiveRequestsAction({}));
		} else {
			yield put(
				uiActions.setAlertAction({
					show: true,
					severity: 'error',
					body: CircularJSON.stringify(response?.data),
				})
			);
		}
	} catch (error) {
		yield put(
			uiActions.setAlertAction({
				show: true,
				severity: 'error',
				body: CircularJSON.stringify(error),
			})
		);
	} finally {
		yield put(uiActions.endLoaderAction());
	}
}

function* _getConfiguration() {
	try {
		const { casinos, reasons } = yield select(({ requests }) => requests);

		yield put(uiActions.startLoaderAction());
		const configuration = yield (!casinos?.length || !reasons?.length) &&
			call(getConfiguration);
		yield configuration &&
			put(requestsActions.getConfigurationSuccessAction(configuration));
		yield put(uiActions.endLoaderAction());
	} catch (error) {
		console.log(CircularJSON.stringify(error));
		yield put(uiActions.endLoaderAction());
	}
}

function* _getAudit({ payload }) {
	const { casinos } = yield select(({ requests }) => requests);

	try {
		// const count = yield call(getTotalCount, 'Audit');
		// yield put(requestsActions.getTotalCountSuccessAction(count));
		yield put(uiActions.startLoaderAction());
		const audit = yield call(getAudit, payload);
		yield audit && put(requestsActions.getAuditSuccessAction(audit));
		const configuration = yield !casinos?.length && call(getConfiguration);
		yield configuration && put(requestsActions.getConfigurationSuccessAction(configuration));
	} catch (error) {
		console.log(CircularJSON.stringify(error));
	} finally {
		yield put(uiActions.endLoaderAction());
	}
}

function* _getArchive({ payload }) {
	try {
		// const count = yield call(getTotalCount, 'ArchivedRequests');
		// yield put(requestsActions.getTotalCountSuccessAction(count));
		yield put(uiActions.startLoaderAction());
		const archive = yield call(getArchive, payload);
		yield archive && put(requestsActions.getArchiveSuccessAction(archive));
	} catch (error) {
		console.log(CircularJSON.stringify(error));
	} finally {
		yield put(uiActions.endLoaderAction());
	}
}

function* _localFix({ payload }) {
	try {
		const response = yield call(localFixFunction, payload);
		console.log('_localFix response', response);
	} catch (e) {
		console.log('_localFix error', e);
	}
}

function* watchRequests() {
	yield takeLatest(
		requestsActions.getActiveRequestsAction.type,
		_getActiveRequests
	);
	yield takeLatest(requestsActions.submitFormAction.type, _submitRequestForm);
	yield takeLatest(requestsActions.localFixAction.type, _localFix);
	yield takeLatest(
		requestsActions.cancelRequestsAction.type,
		_cancelRequests
	);
	yield takeLatest(
		requestsActions.confirmRequestAction.type,
		_confirmRequests
	);
	yield takeLatest(
		requestsActions.deleteRequestsAction.type,
		_deleteRequests
	);
	yield takeLatest(
		requestsActions.getConfigurationAction.type,
		_getConfiguration
	);
	yield takeLatest(requestsActions.getAuditAction.type, _getAudit);
	yield takeLatest(requestsActions.getArchiveAction.type, _getArchive);
}

function* rootRequests() {
	yield fork(watchRequests);
}

export { rootRequests };
