import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import RequireAuth from './RequireAuth';
import NotRequireAuth from './NotRequireAuth';

const Login = React.lazy(() => import('../screens/Login'));
const UsersManagement = React.lazy(() => import('../screens/UsersManagement'));
const Audit = React.lazy(() => import('../screens/Audit'));
const Archive = React.lazy(() => import('../screens/Archive'));
const RequestForm = React.lazy(() => import('../screens/RequestForm'));
const ForgotPassword = React.lazy(() => import('../screens/ForgotPassword'));

const supportRoutes = [
	{
		path: '*',
		element: (
			<RequireAuth>
				<Navigate to='/request_form' />
			</RequireAuth>
		),
	},
	{
		path: '/',
		element: (
			<RequireAuth>
				<Navigate to='/request_form' />
			</RequireAuth>
		),
	},
	{
		path: '/request_form',
		element: (
			<RequireAuth>
				<RequestForm />
			</RequireAuth>
		),
	},
	{
		path: '/login',
		element: (
			<NotRequireAuth>
				<Login />
			</NotRequireAuth>
		),
	},
	{
		path: '/forgot_password',
		element: (
			<NotRequireAuth>
				<ForgotPassword />
			</NotRequireAuth>
		),
	},
];

const adminRoutes = [
	{
		path: '*',
		element: (
			<RequireAuth>
				<Navigate to='/users_management' />
			</RequireAuth>
		),
	},
	{
		path: '/',
		element: (
			<RequireAuth>
				<Navigate to='/users_management' />
			</RequireAuth>
		),
	},
	{
		path: '/users_management',
		element: (
			<RequireAuth>
				<UsersManagement />
			</RequireAuth>
		),
	},
	{
		path: '/request_form',
		element: (
			<RequireAuth>
				<RequestForm />
			</RequireAuth>
		),
	},
	{
		path: '/audit',
		element: (
			<RequireAuth>
				<Audit />
			</RequireAuth>
		),
	},
	{
		path: '/archive',
		element: (
			<RequireAuth>
				<Archive />
			</RequireAuth>
		),
	},
	{
		path: '/login',
		element: (
			<NotRequireAuth>
				<Login />
			</NotRequireAuth>
		),
	},
	{
		path: '/forgot_password',
		element: (
			<NotRequireAuth>
				<ForgotPassword />
			</NotRequireAuth>
		),
	},
];

const routesBuilder = (routes) => {
	return routes?.map((route, i) => {
		return <Route key={i} path={route.path} element={route.element} />;
	});
};

export { supportRoutes, adminRoutes, routesBuilder };
