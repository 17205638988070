import PostAddTwoToneIcon from '@mui/icons-material/PostAddTwoTone';
import AdminPanelSettingsTwoToneIcon from '@mui/icons-material/AdminPanelSettingsTwoTone';
import FactCheckTwoToneIcon from '@mui/icons-material/FactCheckTwoTone';
import ArchiveTwoToneIcon from '@mui/icons-material/ArchiveTwoTone';

const menuItems = [
	{
		items: [
			{
				name: 'Request Form',
				icon: PostAddTwoToneIcon,
				link: '/request_form',
			},
			{
				name: 'User Management',
				icon: AdminPanelSettingsTwoToneIcon,
				link: '/users_management',
			},
			{
				name: 'Audit',
				icon: FactCheckTwoToneIcon,
				link: '/audit',
			},
			{
				name: 'Archive',
				icon: ArchiveTwoToneIcon,
				link: '/archive',
			},
		],
	},
];

export default menuItems;
