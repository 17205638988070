import React from 'react';
import { useTheme } from '@emotion/react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { ContentBox } from './styles';

export const AdminLayout = ({ children }) => {
	const theme = useTheme();

	return (
		<>
			<Header />
			<Sidebar />
			<ContentBox
				sx={{
					[theme.breakpoints.up('lg')]: {
						pl: `${theme.sidebar.width}`,
					},
				}}
			>
				{children}
			</ContentBox>
		</>
	);
};
