import {
	getFirestore,
	collection,
	getDocs,
	query,
	// orderBy,
	// endBefore,
	// limitToLast,
	// startAfter,
	// limit,
	where,
	getCountFromServer,
} from 'firebase/firestore';
import app from '../firebaseConfig';

const db = getFirestore(app);

const getUser = async (email) => {
	const reference = query(
		collection(db, 'Users'),
		where('email', '==', email?.toLowerCase())
	);
	const documentSnapshots = await getDocs(reference);
	let user = documentSnapshots.docs.map((doc) => {
		return { id: doc.id, ...doc.data() };
	});

	return user[0];
};

const getTotalCount = async (col) => {
	let getCount = await getCountFromServer(query(collection(db, col)));
	return getCount.data().count;
};

// const getPrevData = async ({ item, col }) => {
// 	const reference = query(
// 		collection(db, col),
// 		orderBy('requestDate', 'desc'),
// 		endBefore(item.requestDate),
// 		limitToLast(15)
// 	);
// 	const documentSnapshots = await getDocs(reference);

// 	return documentSnapshots.docs.map((doc) => {
// 		return { id: doc.id, ...doc.data() };
// 	});
// };
// const getNextData = async ({ item, col }) => {
// 	const reference = query(
// 		collection(db, col),
// 		orderBy('requestDate', 'desc'),
// 		startAfter(item.requestDate),
// 		limit(15)
// 	);
// 	const documentSnapshots = await getDocs(reference);

// 	return documentSnapshots.docs.map((doc) => {
// 		return { id: doc.id, ...doc.data() };
// 	});
// };

const getActiveRequests = async ({ item, way }) => {
	// if (!item) {
	// 	const first = query(
	// 		collection(db, 'ActiveRequests'),
	// 		orderBy('requestDate', 'desc'),
	// 		limit(15)
	// 	);
	// 	const documentSnapshots = await getDocs(first);

	// 	return documentSnapshots.docs.map((doc) => {
	// 		return { id: doc.id, ...doc.data() };
	// 	});
	// } else {
	// 	return way === 'prev'
	// 		? getPrevData({ item, col: 'ActiveRequests' })
	// 		: getNextData({ item, col: 'ActiveRequests' });
	// }

	const docsSnap = await getDocs(collection(db, 'ActiveRequests'));
	return docsSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

const getConfiguration = async () => {
	const docsSnap = await getDocs(collection(db, 'Configuration'));
	return docsSnap.docs.map((doc) => ({ ...doc.data() }));
};

const getAudit = async ({ item, way }) => {
	// if (!item) {
	// 	const first = query(
	// 		collection(db, 'Audit'),
	// 		orderBy('requestDate', 'desc'),
	// 		limit(15)
	// 	);
	// 	const documentSnapshots = await getDocs(first);

	// 	return documentSnapshots.docs.map((doc) => {
	// 		return { id: doc.id, ...doc.data() };
	// 	});
	// } else {
	// 	return way === 'prev'
	// 		? getPrevData({ item, col: 'Audit' })
	// 		: getNextData({ item, col: 'Audit' });
	// }

	const docsSnap = await getDocs(collection(db, 'Audit'));
	return docsSnap.docs.map((doc) => ({ ...doc.data() }));
};

const getArchive = async ({ item, way }) => {
	// if (!item) {
	// 	const first = query(
	// 		collection(db, 'ArchivedRequests'),
	// 		orderBy('requestDate', 'desc'),
	// 		limit(15)
	// 	);
	// 	const documentSnapshots = await getDocs(first);

	// 	return documentSnapshots.docs.map((doc) => {
	// 		return { id: doc.id, ...doc.data() };
	// 	});
	// } else {
	// 	return way === 'prev'
	// 		? getPrevData({ item, col: 'ArchivedRequests' })
	// 		: getNextData({ item, col: 'ArchivedRequests' });
	// }

	const docsSnap = await getDocs(collection(db, 'ArchivedRequests'));
	return docsSnap.docs.map((doc) => ({ ...doc.data() }));
};

export {
	getUser,
	getTotalCount,
	getActiveRequests,
	getConfiguration,
	getAudit,
	getArchive,
};
