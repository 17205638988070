import { createReducer } from '@reduxjs/toolkit';
import { authActions } from '../actions/auth';

const authState = {
	isAuth: false,
	isAdmin: false,
	error: false,
	user: null,
};

const authReducer = createReducer(authState, (builder) => {
	builder
		.addCase(authActions.signinSuccessAction, (state, { payload }) => {
			state.isAuth = true;
			state.error = false;
		})
		.addCase(authActions.signinFailureAction, (state, { payload }) => {
			state.isAuth = false;
			state.error = true;
		})
		.addCase(authActions.signoutAction, (state, { payload }) => {
			state.isAuth = false;
			state.isAdmin = false;
			state.error = false;
			state.user = null;
		})
		.addCase(authActions.getUserSuccessAction, (state, { payload }) => {
			state.user = payload || null;
			if (payload.accessLevel === 1) {
				state.isAdmin = true;
			}
		})
		.addDefaultCase((state) => state);
});

export default authReducer;
