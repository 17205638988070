import { createAction } from '@reduxjs/toolkit';

export const requestsActions = {
	getAuditAction: createAction('requests/getAudit'),
	getArchiveAction: createAction('requests/getArchive'),
	getAuditSuccessAction: createAction('requests/getAuditSuccess'),
	getArchiveSuccessAction: createAction('requests/getArchiveSuccess'),
	getTotalCountSuccessAction: createAction('requests/getTotalCountSuccess'),
	setDateAction: createAction('requests/setDate'),
	setSelectedRequestAction: createAction('requests/setSelectedRequest'),
	setCasinoFilterAction: createAction('requests/setCasinoFilter'),
	setStatusFilterAction: createAction('requests/setStatusFilter'),
	setReqDateAction: createAction('requests/setReqDate'),
	setLastDateAction: createAction('requests/setLastDate'),
	submitFormAction: createAction('requests/submitForm'),
	cancelRequestsAction: createAction('requests/cancelRequests'),
	confirmRequestAction: createAction('requests/confirmRequest'),
	getActiveRequestsAction: createAction('requests/getActiveRequests'),
	getActiveRequestsSuccessAction: createAction('requests/getActiveRequestsSuccess'),
	getConfigurationAction: createAction('requests/getConfiguration'),
	getConfigurationSuccessAction: createAction('requests/getConfigurationSuccess'),
	clearSelectedRequestAction: createAction('requests/clearSelectedRequest'),
	toggleCancelledRowsAction: createAction('requests/toggleCancelledRows'),
	deleteRequestsAction: createAction('requests/deleteRequests'),
	clearTotalAction: createAction('requests/clearTotal'),
	clearFiltersAction: createAction('requests/clearFilters'),
	localFixAction: createAction('requests/localFix'),
};
