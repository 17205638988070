import * as yup from 'yup';
import { requestsActions } from '../../redux/actions/requests';

export const fields = [
	{
		id: 'fullname',
		label: 'Your full name:',
		placeholder: 'Your name',
		html: 'input',
	},
	{
		id: 'username',
		label: 'Username:',
		placeholder: 'Username',
		html: 'input',
	},
	{
		id: 'casino',
		label: 'Casino:',
		placeholder: 'Select',
		html: 'select',
		optionLabel: 'name',
		required: true,
	},
	{
		id: 'requestDate',
		label: 'Request date:',
		placeholder: 'Select',
		html: 'date',
		required: true,
	},
	{
		id: 'iovation',
		label: 'Iovation flags:',
		placeholder: 'Select',
		html: 'select',
		optionLabel: 'flag',
		required: true,
	},
	{
		id: 'reason',
		label: 'RTBF Request Reason:',
		placeholder: 'Select',
		html: 'select',
		optionLabel: 'reason',
		required: true,
	},
];

export const initialValues = {
	fullname: '',
	username: '',
	casino: { name: '' },
	requestDate: '',
	iovation: { flag: '' },
	reason: { reason: '' },
};

export const validationSchema = yup.object({
	fullname: yup
		.string()
		.trim()
		.max(255)
		.required('Fullname is required')
		.matches(
			/([A-Za-z]+) ([A-Za-z]+)( [A-Za-z]+)*/g,
			'Full name is invalid'
		),
	username: yup.string().trim().max(255).required('Username is required'),
});

const iovation = [
	{ id: 1, flag: 'Yes' },
	{ id: 2, flag: 'No' },
];

export const getOptions = ({ casinos, reasons, field }) => {
	switch (field.id) {
		case 'casino':
			return casinos;
		case 'iovation':
			return iovation;
		case 'reason':
			return reasons;
		default:
			return [];
	}
};

export const submitForm = async ({
	values,
	date,
	dispatch,
	isAdmin,
	navigate,
	resetForm,
}) => {
	values.requestDate = date;
	values.fullname = values.fullname.trim();
	values.username = values.username.trim();

	dispatch(
		requestsActions.submitFormAction({
			values,
			isAdmin,
			navigate,
			resetForm,
		})
	);
};
