import { styled, Box } from '@mui/material';

export const PrivateWrapper = styled(Box)(
	({ theme }) => `
    flex: 1 1 auto;
    display: flex;
    height: 100%;
    background-color: ${theme.palette.background.default};
    
    @media (min-width: ${theme.breakpoints.values.lg}px) {
        padding-left: ${theme.sidebar.width};
    }
  `
);
export const PublicWrapper = styled(Box)(
	({ theme }) => `
    flex: 1 1 auto;
    display: flex;
    height: 100%;
    background-color: ${theme.palette.background.default};
  `
);
export const PrivateContent = styled(Box)(
	({ theme }) => `
    margin-top: ${theme.header.height};
    height: calc(100vh - (${theme.header.height}));
    position: relative;
    flex: 1 1 auto;
    overflow: auto;
  `
);
export const PublicContent = styled(Box)(
	({ theme }) => `
    height: 100vh;
    position: relative;
    flex: 1 1 auto;
    overflow: auto;
  `
);
export const MainContent = styled(Box)(
	({ theme }) => `
    height: calc(100vh - (${theme.header.height}));
    position: relative;
    flex: 1 1 auto;
    overflow: auto;
  `
);
