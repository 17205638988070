import React from 'react';
import Header from '../components/Header';
import { ContentBox } from './styles';

export const SupportLayout = ({ children }) => {
	return (
		<>
			<Header />
			<ContentBox>{children}</ContentBox>
		</>
	);
};
