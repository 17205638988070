const statusType = {
	CANCELLED: 'Cancelled',
	IN_PROGRESS: 'In progress',
	FAILED: 'Failed',
	DELETE: 'Delete',
	DELETED: 'Deleted',
	SUCCESS: 'Success',
	DONE: 'Done',
	NEW: 'New',
};

export { statusType };
