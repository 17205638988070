import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Private from './navigation/Private';
import Public from './navigation/Public';
import app from './firebaseConfig';
import ThemeProvider from './theme/ThemeProvider';
import { authActions } from './redux/actions/auth';

const App = () => {
	const auth = getAuth(app);
	const dispatch = useDispatch();
	const { isAuth, user } = useSelector(({ auth }) => auth, shallowEqual);

	onAuthStateChanged(auth, (f_user) => {
		if (f_user) {
			!user && dispatch(authActions.getUserAction(f_user.email));
			dispatch(authActions.signinSuccessAction());
		} else {
			dispatch(authActions.signoutAction());
		}
	});

	return <ThemeProvider>{_buildContent(isAuth)}</ThemeProvider>;
};

const _buildContent = (isAuth) => {
	return isAuth ? <Private /> : <Public />;
};

export default App;
