import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
	Box,
	IconButton,
	Tooltip,
	Typography,
	LinearProgress,
} from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import { uiActions } from '../../redux/actions/ui';
import { signout } from '../helpers';
import { HeaderWrapper } from '../styles';

const Header = () => {
	const dispatch = useDispatch();
	const isAdmin = useSelector(({ auth }) => auth.isAdmin, shallowEqual);
	const { sidebarOpen, loading } = useSelector(({ ui }) => ui, shallowEqual);
	const toggleSidebar = () => {
		dispatch(uiActions.toggleSidebarAction());
	};

	return (
		<Box display={'flex'} sx={{flexFlow: 'column', position: 'relative', justifyContent: 'space-between'}}>
			<HeaderWrapper>
				{isAdmin && (
					<Box
						sx={{
							display: { lg: 'none', md: 'block', xs: 'block' },
						}}
					>
						<Tooltip arrow title='Menu'>
							<IconButton
								sx={{ color: '#fff' }}
								onClick={toggleSidebar}
							>
								{!sidebarOpen ? (
									<MenuTwoToneIcon />
								) : (
									<CloseTwoToneIcon />
								)}
							</IconButton>
						</Tooltip>
					</Box>
				)}
				<Box display='flex' alignItems='center'>
					<Typography variant='h1' sx={{ color: '#fff' }}>
						RTBFmator
					</Typography>
					{/* <Typography sx={{backgroundColor:'#fff', marginLeft: 12, padding: 1}} fontSize={18} color='error'>Under maintanance. Please do not run any flow, since the emails are going to test accounts</Typography> */}
				</Box>
				<Box display='flex' alignItems='center'>
					{/* <HeaderUserBox /> */}
					<Box>
						<Tooltip arrow title='Log out'>
							<IconButton
								sx={{ color: '#fff' }}
								onClick={() => signout(dispatch)}
							>
								<LogoutTwoToneIcon />
							</IconButton>
						</Tooltip>
					</Box>
				</Box>
			</HeaderWrapper>
			{loading && <LinearProgress sx={{position: 'fixed', width: '100%', zIndex: 999, top: '60px'}} color='primary' />}
		</Box>
	);
};

export default Header;
