import { Box, Drawer } from '@mui/material';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { uiActions } from '../../redux/actions/ui';
import SidebarMenu from './SidebarMenu';
import { SidebarWrapper } from '../styles';

const Sidebar = () => {
	const dispatch = useDispatch();
	const { sidebarOpen } = useSelector(({ ui }) => ui, shallowEqual);
	
    const toggleSidebar = () => {
		dispatch(uiActions.toggleSidebarAction());
	};

	return (
		<>
			<Box sx={{ display: { lg: 'block' } }}>
				<SidebarWrapper
					sx={{
						display: {
							xs: 'none',
							lg: 'inline-block',
						},
						position: 'fixed',
						left: 0,
						top: 0,
					}}
				>
					<SidebarMenu />
				</SidebarWrapper>
			</Box>
			<Box sx={{ display: { lg: 'none' } }}>
				<Drawer
					anchor='left'
					open={sidebarOpen}
					onClose={toggleSidebar}
					variant='temporary'
					elevation={9}
					sx={{
						zIndex: 1302,
					}}
				>
					<SidebarWrapper>
						<SidebarMenu isDrawer />
					</SidebarWrapper>
				</Drawer>
			</Box>
		</>
	);
};

export default Sidebar;
