import { styled, Box } from '@mui/material';

export const ContentBox = styled(Box)(
	({ theme }) => `
    position: relative;
    z-index: 5;
    flex: 1;
    display: flex;
    padding: 18px;
    padding-top: ${theme.header.height};    
`
);
