import React from 'react';
import { useDispatch } from 'react-redux';
import { Alert as MUIAlert, Snackbar } from '@mui/material';
import { uiActions } from '../../redux/actions/ui';

const Alert = ({ alert }) => {
	const dispatch = useDispatch();
	const handleClose = () => {
		dispatch(
			uiActions.setAlertAction({
				show: false,
				severity: 'info',
				body: '',
			})
		);
	};

	return (
		<Snackbar
			anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
			autoHideDuration={3000}
			open={alert.show}
			onClose={handleClose}
			sx={{
				top: '70px !important',
				left: 'calc(50% + 145px) !important',
			}}
		>
			<MUIAlert severity={alert.severity}>{alert.body}</MUIAlert>
		</Snackbar>
	);
};

export default Alert;
