import { signOut, getAuth } from 'firebase/auth';
import { authActions } from '../redux/actions/auth';
import { requestsActions } from '../redux/actions/requests';
import app from '../firebaseConfig';

export const externalSub = [
	{ id: 'requestTime', label: 'Req time' },
	{ id: 'responseTime', label: 'Resp time' },
	{ id: 'status', label: 'Status' },
	{ id: 'reason', label: 'Reason' },
];
export const auditExternal = [
	{
		id: 'dotmailer',
		label: 'Dotmailer',
		colSpan: Number(externalSub?.length),
	},
	{ id: 'optimove', label: 'Optimove', colSpan: Number(externalSub?.length) },
	{
		id: 'intelliSMS',
		label: 'IntelliSMS',
		colSpan: Number(externalSub?.length),
	},
	{
		id: 'xtremePush',
		label: 'XtremePush',
		colSpan: Number(externalSub?.length),
	},
	{
		id: 'iovation',
		label: 'Iovation',
		colSpan: Number(externalSub?.length),
	},
];
export const headers = {
	audit: [
		{ id: 'username', label: 'Username', rowSpan: 2 },
		{ id: 'casino', label: 'Casino', rowSpan: 2 },
		{ id: 'requestDate', label: 'Request date', rowSpan: 2 },
		{ id: 'lastChange', label: 'Last status change', rowSpan: 2 },
		{ id: 'status', label: 'Request status', rowSpan: 2 },
		{ id: 'iovationFlag', label: 'Iovation flag', rowSpan: 2 },
		...auditExternal,
	],
	archive: [
		{ id: 'playerUsername', label: 'Username' },
		{ id: 'casino', label: 'Casino' },
		{ id: 'requestDate', label: 'Request date' },
		{ id: 'status', label: 'Status' },
	],
	request: [
		{ id: 'action', label: ['New', 'IP'], hasExtra: true },
		{ id: 'playerUsername', label: 'Username' },
		{ id: 'casino', label: 'Casino' },
		{ id: 'requestDate', label: 'Request date' },
		{ id: 'softDeadline', label: 'Soft deadline' },
		{ id: 'hardDeadline', label: 'Hard deadline' },
		{ id: 'lastChange', label: 'Last change date' },
		{ id: 'status', label: 'Status' },
	],
};

// export const auditHeaders = [
// 	{ id: 'action', label: '', hasExtra: true },
// 	{ id: 'playerUsername', label: 'Username' },
// 	{ id: 'casino', label: 'Casino' },
// 	{ id: 'requestDate', label: 'Request date' },
// 	{ id: 'status', label: 'Status' },
// ];

export const handleComparatorCases = (a, b, orderBy) => {
	switch (orderBy) {
		case 'requestDate':
		case 'lastChange':
		case 'softDeadline':
		case 'hardDeadline':
			let timeA =
				typeof a.requestDate === 'string'
					? new Date(a.requestDate || 0).getTime()
					: a.requestDate?.toDate()?.getTime();
			let timeB =
				typeof b.requestDate === 'string'
					? new Date(b.requestDate || 0).getTime()
					: b.requestDate?.toDate()?.getTime();
			if (timeB < timeA) {
				return -1;
			}
			if (timeB > timeA) {
				return 1;
			}
			return 0;
		case 'dotmailer':
		case 'optimove':
		case 'intelliSMS':
		case 'xtremePush':
			return 0;
		default:
			if (!!b?.[orderBy] && !!a?.[orderBy]) {
				let originalA =
					a[orderBy]?.name?.toLowerCase() || a[orderBy]?.toLowerCase();
				let originalB =
					b[orderBy]?.name?.toLowerCase() || b[orderBy]?.toLowerCase();
				if (originalB < originalA) {
					return -1;
				}
				if (originalB > originalA) {
					return 1;
				}
				return 0;
			}
			return 0;
	}
};

export const deleteSelectedRequest = ({ data, dispatch }) => {
	const reducedData = data.map((player) => ({
		id: player.id,
		email: player?.email,
		phone: player?.phone,
		playerCode: player?.playerCode,
		casino: player.casino,
		iovationFlag: player.iovation.flag,
		username: player.playerUsername,
		requestDate: player.requestDate?.toMillis(),
	}));

	dispatch(requestsActions.deleteRequestsAction(reducedData));
};

export const cancelSelectedRequest = async ({ selectedRequests, dispatch }) => {
	const onlyIDs = selectedRequests?.map(el => el.id);
	dispatch(requestsActions.cancelRequestsAction(onlyIDs));
};

export const confirmSelectedRequest = async ({ selectedRequests, dispatch }) => {
	const onlyIDs = selectedRequests?.map(el => el.id);
	dispatch(requestsActions.confirmRequestAction(onlyIDs));
};

export const signout = (dispatch) => {
	const auth = getAuth(app);
	signOut(auth).then(() => {
		dispatch(authActions.signoutAction());
	});
};
