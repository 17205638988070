import { fork, call, takeLatest, put } from 'redux-saga/effects';
import { authActions } from '../actions/auth';
import { uiActions } from '../actions/ui';
import { getUser } from '../../services/db';

function* _getUser({payload}) {
    yield put(uiActions.startLoaderAction());
	const user = yield call(getUser, payload);
    yield put(authActions.getUserSuccessAction(user));
    yield put(uiActions.endLoaderAction());
}

function* watchAuth() {
	yield takeLatest(authActions.getUserAction.type, _getUser);
}

function* rootAuth() {
	yield fork(watchAuth);
}

export { rootAuth };
