import { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { Button, Collapse, ListItem } from '@mui/material';
import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { useDispatch } from 'react-redux';
import { uiActions } from '../../../redux/actions/ui';

const SidebarMenuItem = ({
	children,
	link,
	icon: Icon,
	badge,
	badgeTooltip,
	open: openParent,
	active,
	name,
	...rest
}) => {
	const dispatch = useDispatch();
	const [menuToggle, setMenuToggle] = useState(openParent);

	const toggleMenu = () => {
		setMenuToggle((Open) => !Open);
	};

	const toggleSidebar = () => {
		dispatch(uiActions.toggleSidebarAction('false'));
	};

	if (children) {
		return (
			<ListItem
				component='div'
				className='Mui-children'
				key={name}
				{...rest}
			>
				<Button
					className={clsx({ 'Mui-active': menuToggle })}
					startIcon={Icon && <Icon />}
					endIcon={
						menuToggle ? (
							<ExpandLessTwoToneIcon />
						) : (
							<ExpandMoreTwoToneIcon />
						)
					}
					onClick={toggleMenu}
				>
					{name}
				</Button>
				<Collapse in={menuToggle}>{children}</Collapse>
			</ListItem>
		);
	}

	return (
		<ListItem component='div' key={name} {...rest}>
			<Button
				disableRipple
				activeClassName='Mui-active'
				component={RouterLink}
				onClick={toggleSidebar}
				to={link}
				startIcon={Icon && <Icon />}
			>
				{name}
			</Button>
		</ListItem>
	);
};

export default SidebarMenuItem;
