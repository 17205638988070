import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const NotRequireAuth = ({ children }) => {
	const { isAuth } = useSelector(({ auth }) => auth, shallowEqual);
	const location = useLocation();

	return isAuth === true ? (
		<Navigate to={location?.state?.path || '/request_form'} replace />
	) : (
		children
	);
};

export default NotRequireAuth;
