import { ListSubheader, List } from '@mui/material';
import { useLocation, matchPath } from 'react-router-dom';
import SidebarMenuItem from './item';
import menuItems from './items';
import { MenuWrapper, SubMenuWrapper } from '../../styles';

const renderSidebarMenuItems = ({ items, path }) => (
	<SubMenuWrapper>
		<List component='div'>
			{items.reduce(
				(ev, item) => reduceChildRoutes({ ev, item, path }),
				[]
			)}
		</List>
	</SubMenuWrapper>
);

const reduceChildRoutes = ({ ev, path, item }) => {
	const key = item.name;

	const exactMatch = item.link
		? !!matchPath(
				{
					path: item.link,
					end: true,
				},
				path
		  )
		: false;

	if (item.items) {
		const partialMatch = item.link
			? !!matchPath(
					{
						path: item.link,
						end: false,
					},
					path
			  )
			: false;

		ev.push(
			<SidebarMenuItem
				key={key}
				active={partialMatch}
				open={partialMatch}
				name={item.name}
				icon={item.icon}
				link={item.link}
				badge={item.badge}
				badgeTooltip={item.badgeTooltip}
			>
				{renderSidebarMenuItems({
					path,
					items: item.items,
				})}
			</SidebarMenuItem>
		);
	} else {
		ev.push(
			<SidebarMenuItem
				key={key}
				active={exactMatch}
				name={item.name}
				link={item.link}
				badge={item.badge}
				badgeTooltip={item.badgeTooltip}
				icon={item.icon}
			/>
		);
	}

	return ev;
};

function SidebarMenu() {
	const location = useLocation();

	return (
		<>
			{menuItems.map((section, i) => (
				<MenuWrapper key={i}>
					<List
						component='div'
						subheader={
							<ListSubheader component='div' disableSticky>
								{section.heading}
							</ListSubheader>
						}
					>
						{renderSidebarMenuItems({
							items: section.items,
							path: location.pathname,
						})}
					</List>
				</MenuWrapper>
			))}
		</>
	);
}

export default SidebarMenu;
