import { getFunctions, httpsCallable } from 'firebase/functions';
import CircularJSON from 'circular-json';
import app from '../firebaseConfig';

const functions = getFunctions(app, 'europe-west3');

const validateUser = async (data) => {
	const validatePlayer = httpsCallable(functions, 'validatePlayer');
	try {
		return await validatePlayer(data);
	} catch (error) {
		return CircularJSON.stringify(error);
	}
};

const submitRequestForm = async (data) => {
	const submitRequestForm = httpsCallable(functions, 'submitForm');
	try {
		return await submitRequestForm(data);
	} catch (error) {
		return CircularJSON.stringify(error);
	}
};

const cancelRequestsFunction = async (data) => {
	const cancelRequestF = httpsCallable(functions, 'cancelRequest');
	try {
		return await cancelRequestF(data);
	} catch (error) {
		return CircularJSON.stringify(error);
	}
};

const confirmRequestsFunction = async (data) => {
	const confirmRequestF = httpsCallable(functions, 'confirmRequest');
	try {
		return await confirmRequestF(data);
	} catch (error) {
		return CircularJSON.stringify(error);
	}
};

const deleteRequestFunction = async (data) => {
	const deleteRequests = httpsCallable(functions, 'deleteRequest');
	try {
		return await deleteRequests(data);
	} catch (error) {
		return CircularJSON.stringify(error);
	}
};

const localFixFunction = async (data) => {
	const localFixF = httpsCallable(functions, 'localFixRequest');
	try {
		return await localFixF(Number(data));
	} catch (error) {
		return CircularJSON.stringify(error);
	}
};

export {
	validateUser,
	submitRequestForm,
	cancelRequestsFunction,
	confirmRequestsFunction,
	deleteRequestFunction,
	localFixFunction,
};
