import { createAction } from '@reduxjs/toolkit';

export const authActions = {
	signinAction: createAction('auth/signin'),
	signinSuccessAction: createAction('auth/signinSuccess'),
	signinFailureAction: createAction('auth/signinFailure'),
	signoutAction: createAction('auth/signout'),
	getUserAction: createAction('auth/getUser'),
	getUserSuccessAction: createAction('auth/getUserSuccess'),
};
