import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
	apiKey: 'AIzaSyBlynXocyGolvUJUzLWx3f9TuWNSa3Wosw',
	authDomain: 'rtbfmator.firebaseapp.com',
	projectId: 'rtbfmator',
	storageBucket: 'rtbfmator.appspot.com',
	messagingSenderId: '1096525554227',
	appId: '1:1096525554227:web:8cd6cafc2dd8f65ab63946',
	measurementId: 'G-XWZ5CQDP3B',
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);

export default app;
